import './src/styles/global.css'; // eslint-disable-line

let hash;

export const onClientEntry = () => {
  // prevent default browser "jump to url's hash section" behavior
  if (window.location.hash) {
    hash = window.location.hash;

    history.replaceState(
      null,
      document.title,
      window.location.href.replace(window.location.hash, ''),
    );
  }
};

export const onInitialClientRender = () => {
  if (!hash) return;

  // scroll to section with id from url's hash
  requestAnimationFrame(() => {
    const element = document.querySelector(hash);

    if (element) {
      const topOffset = document.querySelector('#main-header.static') ? 0 : 80;
      const top =
        element.getBoundingClientRect().top + window.scrollY - topOffset;

      window.scrollTo(0, top);
    }
  });
};

export const shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition,
}) => {
  // overwrite wrong default browser scroll positioning
  requestAnimationFrame(() => {
    window.scrollTo(...(getSavedScrollPosition(location) || [0, 0]));
  });

  return false;
};
